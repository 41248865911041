import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import imgLogo from './../../images/logo_lampejo.webp';
import imgLogo187 from './../../images/logo_lampejo_187.webp';
import imgBanner from './../../images/banner-perdido-nos-estudos.webp';
import Helmet from 'react-helmet';

const FooterComponent = lazy(() => import('./../../components/footer'));
const AppDownloadComponent = lazy(() => import('./../../components/appDownload'));
const blogPosts = require("../../controllers/blogPost")
const platform = require("platform")

const FacebookShareButton = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.FacebookShareButton })));
const TwitterShareButton = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.TwitterShareButton })));
const WhatsappShareButton = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.WhatsappShareButton })));
const EmailShareButton = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.EmailShareButton })));
const FacebookIcon = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.FacebookIcon })));
const TwitterIcon = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.TwitterIcon })));
const WhatsappIcon = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.WhatsappIcon })));
const EmailIcon = lazy(() => import(`react-share/es/index`).then(module=>({ default:module.EmailIcon })));

export default function Home() {
  const [ isMobile, setIsMobile ] = useState(window.screen.width < 700);
  const [ post, setPost ] = useState({ title: "...", keywords: null });
  const [ isLoading, setIsLoading ] = useState(true);

  const params = useParams();

  useEffect(async () => {
    const reqBlogPosts = await blogPosts.getById(params.title);
    const getBlogPosts = reqBlogPosts.body.post;

    setPost(getBlogPosts);
    setIsLoading(false);
  }, [params]);

  return (
    <>
      <Helmet
        title={`${post.title} - Blog da Lampejo`}
        meta={[
          {
            name: "description",
            content: post.description || post.content?.replace(/<[^>]*>?/gm, '').substring(0, 150) || "..."
          },
          {
            name: "keywords",
            content: post.keywords || ""
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property:"og:url",
            content: post.shareUrl || ""
          },
          {
            property:"og:title",
            content: `${post.title || "..."} - Blog da Lampejo`
          },
          {
            property: "og:description",
            content: post.description || post.content?.replace(/<[^>]*>?/gm, '').substring(0, 150) || "..."
          },
          {
            property: "og:image",
            content: post.imgSrc || ""
          },
          {
            property: "twitter:card",
            content: "summary_large_image"
          },
          {
            property: "twitter:url",
            content: post.shareUrl || ""
          },
          {
            property:"twitter:title",
            content: `${post.title || "..."} - Blog da Lampejo`
          },
          {
            property: "twitter:description",
            content: post.description || post.content?.replace(/<[^>]*>?/gm, '').substring(0, 150) || "..."
          },
          {
            property: "twitter:image",
            content: post.imgSrc || ""
          }
        ]}
      />
      { !isMobile && 
        <nav className="navHome">
          <div className="maxWidthMd1000">
            <Link to="/">
              <img className="maxWidthMd187" src={imgLogo187} />
            </Link>
          </div>
        </nav>
      }

      <div className="rowMd justifyCenterMd">
        <div className="maxWidthMd760NoMargin">
          <div className="container topMdMinus15 bottomZero paddingLeftMd0">
            { !!isMobile &&
              <div className="logo">
                <Link to="/">
                  <img src={imgLogo} alt="logo Lampejo"/> 
                </Link>
              </div>
            }

            <div className="rowMd blogTitle containerMd">
              <div className="justifyCenterMd">
                <h1>{post.title}</h1>
                <h2>{post.subtitle? post.subtitle : null}</h2>
              </div>
            </div>
            <div className="maxWidthMd1000 containerMd paddingTop0 paddingBottom10 paddingLeftMd0">
              <span>Editorial da Lampejo.app</span>
              <span>{post.date} · Tempo de Leitura: {post.readTime}</span>
            </div>
          </div>
          
          <div className="alignSelfCenterMd paddingSm15">
            <div className="rowMd alignContentCenter">
              <div className="blogCard">
                <div dangerouslySetInnerHTML={{ __html: post.content }}/>
              </div>
            </div>
          </div>
        </div>
        <div className="maxWidthSm300 maxWidthMd300 minWidthMd300">
          <div className="lostBanner fixed" style={{ backgroundImage: `url(${imgBanner})` }}>
            <div className="buttonContainer">
              <div className={`bannerButton`} onClick={() => { 
                if (isMobile) {
                  if (platform.os.toString().includes('iOS')) {
                    window.location.href = 'https://apps.apple.com/us/app/lampejo-educa%C3%A7%C3%A3o/id1584002205'
                  } else {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.lampejo.lampejoeducacao&hl=pt'
                  }
                } else {
                  window.location.href = 'https://lampejo.link/register'
                }
                }}>
                <div className={`buttonInner`}>
                  Solicite agora!
                </div>
              </div>
            </div>
          </div>

          <div className={`shareCard fixed marginTop300`}>
            <h3>Compartilhe</h3>
            <p>esse artigo com seus amigos!</p>
            <div className={'justifyContentSpaceEvenly row'}>
              <div className={`px-1`}>
                <Suspense fallback={<div>...</div>}>
                  <FacebookShareButton
                    url={post.shareUrl}
                    quote={post.title}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </Suspense>
              </div>

              <div className={`px-1`}>
                <Suspense fallback={<div>...</div>}>
                  <TwitterShareButton
                    url={post.shareUrl}
                    title={post.title}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </Suspense>
              </div>

              <div className={`px-1`}>
                <Suspense fallback={<div>...</div>}>
                  <WhatsappShareButton
                    url={post.shareUrl}
                    title={post.title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </Suspense>
              </div>

              <div className={`px-1`}>
                <Suspense fallback={<div>...</div>}>
                  <EmailShareButton
                    url={post.shareUrl}
                    subject={post.title}
                    body="body"
                    className="Demo__some-network__share-button"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <FooterComponent/>
      </Suspense>

      { !!isMobile &&
        <Suspense fallback={<div></div>}>
          <AppDownloadComponent/>
        </Suspense>
      }
    </>
  );
}
