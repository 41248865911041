import React, { useEffect } from 'react';

export default function Redirect(props) {
  useEffect(async () => {
    window.location = props.url;
  }, []);

  return (
    <>
      <p>Redirecionando...</p>
    </>
  );
}
