const domains = () => {
  return {
    api: 'https://rfiau9zx48.execute-api.us-east-1.amazonaws.com' // 'http://localhost:3002'
  }  
}

export const get = async () => {
  const response = await requestApi('/blogPosts')
  if (response.status < 200 || response.status >= 300) {
    response.ok = false
    return response  
  }
  return response
}
 
export const getById = async (id) => {
  const response = await requestApi('/blogPost/' + id, 'GET', null, {})

  if (response.status < 200 || response.status >= 300) {
   response.ok = false
   return response  
  }
  return response
}

const requestApi = async (path = '', method = 'GET', data = null, headers = {}) => {
  if (!domains().api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${domains().api}${path}`
 
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers
  )
 
  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: 'cors',
    cache: 'no-cache',
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })
 
  if (response.status < 200 || response.status >= 300) {
    let res = await response.json()
    res.status = response.status
    return await res
  }
 
  return {
    status: 200,
    ok: true,
    body: await response.json()
  }
}