import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import TagManager from 'react-gtm-module';

import Home from "./pages/Home";
import Blog from "./pages/Blog/index";
import Article from "./pages/Blog/Article";
import Redirect from './pages/Redirect';
import NotFound from './pages/NotFound';

const tagManagerArgs = {
  gtmId: 'G-VDC2S2T3FR'
}
TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>

      <Route path="/blog/:title" element={<Article />} />
      <Route path="/blog" element={<Blog />} />

      <Route path="/register" element={ <Redirect url="https://lampejo.link/register"/> } />
      <Route path="/login" element={ <Redirect url="https://lampejo.link/login"/> } />
      <Route path="/schedule" element={ <Redirect url="https://lampejo.link/schedule"/> } />

      <Route exact path="/" element={<Home />} />
      <Route path='*' element={<NotFound status={404} />} />

    </Routes>
  </BrowserRouter>,
  rootElement
);