import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import imgLogo from './../../images/logo_lampejo.webp';
import imgLogo187 from './../../images/logo_lampejo_187.webp';
import Helmet from 'react-helmet';

const blogPosts = require("../../controllers/blogPost")

const BlogPostComponent = lazy(() => import('./../../components/blogPost'));
const FooterComponent = lazy(() => import('./../../components/footer'));
const AppDownloadComponent = lazy(() => import('./../../components/appDownload'));

export default function Home() {
  const [ isMobile, setIsMobile ] = useState(window.screen.width < 700);
  const [ posts, setPosts ] = useState([]);

  useEffect(async () => {
    const reqBlogPosts = await blogPosts.get();
    const getBlogPosts = reqBlogPosts.body.posts;

    setPosts(getBlogPosts);
  }, []);

  return (
    <>
    <Helmet
        title={`Blog da Lampejo | O blog do ENEM cheio de dicas de estudos!`}
        meta={[
          {
            name: "description",
            content: "No blog da Lampejo você encontra as melhores dicas para mandar bem no ENEM e na redação!"
          },
          {
            name: "keywords",
            content: "blog da lampejo, blog do enem, dicas de estudos, dicas de redação, dicas para redação, blogdoenem"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property:"og:url",
            content: "https://www.lampejo.app/blog"
          },
          {
            property:"og:title",
            content: "Blog da Lampejo | O blog do ENEM cheio de dicas de estudos!"
          },
          {
            property: "og:description",
            content: "No blog da Lampejo você encontra as melhores dicas para mandar bem no ENEM e na redação!"
          },
          {
            property: "og:image",
            content: "https://www.lampejo.app/logo_lampejo_187.c2155e53.webp"
          },
          {
            property: "twitter:card",
            content: "summary_large_image"
          },
          {
            property: "twitter:url",
            content: "https://www.lampejo.app/blog"
          },
          {
            property:"twitter:title",
            content: `Blog da Lampejo | O blog do ENEM cheio de dicas de estudos!`
          },
          {
            property: "twitter:description",
            content: "No blog da Lampejo você encontra as melhores dicas para mandar bem no ENEM e na redação!"
          },
          {
            property: "twitter:image",
            content: "https://www.lampejo.app/logo_lampejo_187.c2155e53.webp"
          }
        ]}
      />
      { !isMobile && 
        <nav className="navHome">
          <div className="maxWidthMd1000">
            <Link to="/">
              <img className="maxWidthMd187" src={imgLogo187} />
            </Link>
          </div>
        </nav>
      }
      <div className="container topMdMinus15 bottomZero">
        { !!isMobile &&
          <div className="logo">
            <Link to="/">
              <img src={imgLogo} alt="logo Lampejo"/> 
            </Link>
          </div>
        }

        <div className="rowMd center">
          <div className="justifyCenterMd">
            <h2>Nosso Blog</h2>
          </div>
        </div>
      </div>
      
      <div className="container alignSelfCenterMd">
        <div className="rowMd alignSelfCenterMd alignContentCenter grid maxWidthMd1000 justifyCenterMd">
          { posts.map((post, index) => { return (
            <Suspense key={index} fallback={<div>...</div>}>
              <BlogPostComponent title={post.title} url={post.id} imgSrc={post.imgSrc} imgSkeletonColor={post.imgSkeletonColor}/>
              {(index + 1) % 3 == 0 && <div className="line-break"></div>}
            </Suspense>
          )})}
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <FooterComponent/>
      </Suspense>

      { !!isMobile &&
        <Suspense fallback={<div></div>}>
          <AppDownloadComponent/>
        </Suspense>
      }
    </>
  );
}
