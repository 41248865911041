import React, { Suspense, lazy, useState } from 'react';
import { Link } from 'react-router-dom';

import imgLogo from './../images/logo_lampejo.webp';
import imgLogo187 from './../images/logo_lampejo_187.webp';
import imgStudent from './../images/estudante_lampejo.svg';
import imgBackground from './../images/background.svg';
import imgBusPoint from './../images/ilustracao_ponto_de_onibus.svg';
import imgGlobe from'./../images/globe.webp';
import imgCatracaLivre from './../images/logo-catraca-livre.webp'
import imgIG from './../images/logo-ig.webp'
import imgNoticiasDoES from './../images/logo-noticias-do-es.webp'
import imgODebate from './../images/logo-odebate.webp'
import Helmet from 'react-helmet';

const IoIosAppstore = lazy(() => import(`react-icons/io/index`).then(module=>({ default:module.IoIosAppstore })))
const IoMdAppstore = lazy(() => import(`react-icons/io/index`).then(module=>({ default:module.IoMdAppstore })))
const CarouselComponent = lazy(() => import('./../components/carousel'));
const BlogPostComponent = lazy(() => import('./../components/blogPost'));
const FooterComponent = lazy(() => import('./../components/footer'));
const AppDownloadComponent = lazy(() => import('./../components/appDownload'));
const JournalPostComponent = lazy(() => import('./../components/journalPost'));

export default function Home() {
  const [ isMobile, setIsMobile ] = useState(window.screen.width < 700);
  const [ posts, setPosts ] = useState([
    { 
      title: "Como fazer a inscrição no ENEM?",
      url: "como-fazer-a-inscricao-no-enem",
      imgSrc: "https://lampejo-upload-imagens.s3.amazonaws.com/icones_de_provas_do_enem.webp",
      imgSkeletonColor: "#fff"
    },
    { 
      title: "O que é o ENEM? Como funciona o ENEM",
      url: "o-que-e-o-enem",
      imgSrc: "https://lampejo-upload-imagens.s3.amazonaws.com/alunos_realizando_a_prova_do_ENEM.webp",
      imgSkeletonColor: "#e1dcd7"
    },
    { 
      title: "Como funciona a pontuação no ENEM?",
      url: "como-funciona-a-pontuacao-no-enem",
      imgSrc: "https://lampejo-upload-imagens.s3.amazonaws.com/caderno_e_caneta_sobre_uma_mesa_de_madeira.webp",
      imgSkeletonColor: "#a6886c"
    }
  ]);

  const [ journalPosts, setJournalPosts ] = useState([
    { 
      url: "https://catracalivre.com.br/educacao/5-apps-essenciais-para-auxiliar-na-preparacao-do-enem/",
      imgSrc: imgCatracaLivre,
      imgSkeletonColor: "#fff"
    },
    { 
      url: 'https://empreendaexito.ig.com.br/empreendedorismo/2022-04-25/5-aplicativos-que-ajudam-na-aprendizagem-de-estudantes.html',
      imgSrc: imgIG,
      imgSkeletonColor: "#e1dcd7"
    },
    { 
      url: "https://noticiasdoes.com.br/noticia/5497/5-aplicativos-que-ajudam-na-aprendizagem-de-estudantes",
      imgSrc: imgNoticiasDoES,
      imgSkeletonColor: "#a6886c"
    },
    { 
      url: "https://www.odebate.com.br/startup-news/cindo-aplicativos-que-ajudam-na-aprendizagem-de-estudantes.html",
      imgSrc: imgODebate,
      imgSkeletonColor: "#a6886c"
    }
  ]);

  return (
    <>
      <Helmet
        title={`Lampejo | Cronograma de estudo gratuito para o ENEM!`}
        meta={[
          {
            name: "description",
            content: "A Lampejo é seu plano de estudos eficaz! O aplicativo grátis para estudar para o ENEM, perfeito para criar seu cronograma de estudos e fazer simulados!"
          },
          {
            name: "keywords",
            content: "lampejo, cronograma lampejo, cronograma de estudo, app para criar cronograma de estudos, aplicativos gratis para estudar pro enem, plano de estudo, simulados enem online"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property:"og:url",
            content: "https://www.lampejo.app/"
          },
          {
            property:"og:title",
            content: "Lampejo | Cronograma de estudo gratuito para o ENEM!"
          },
          {
            property: "og:description",
            content: "A Lampejo é seu plano de estudos eficaz! O aplicativo grátis para estudar para o ENEM, perfeito para criar seu cronograma de estudos e fazer simulados!"
          },
          {
            property: "og:image",
            content: "https://www.lampejo.app/logo_lampejo_187.c2155e53.webp"
          },
          {
            property: "twitter:card",
            content: "summary_large_image"
          },
          {
            property: "twitter:url",
            content: "https://www.lampejo.app/"
          },
          {
            property:"twitter:title",
            content: `Lampejo | Cronograma de estudo gratuito para o ENEM!`
          },
          {
            property: "twitter:description",
            content: "A Lampejo é seu plano de estudos eficaz! O aplicativo grátis para estudar para o ENEM, perfeito para criar seu cronograma de estudos e fazer simulados!"
          },
          {
            property: "twitter:image",
            content: "https://www.lampejo.app/logo_lampejo_187.c2155e53.webp"
          }
        ]}
        link = {[
          { rel : 'canonical', href : "https://www.lampejo.app" }
        ]}    
      />
      { !isMobile && 
        <nav className="navHome">
          <div className="maxWidthMd1000">
            <Link to="/">
              <img className="maxWidthMd187" src={imgLogo187} alt="Logo da Lampejo" title="Logo Lampejo"/>
            </Link>
          </div>
        </nav>
      }
      <div className="container topMdMinus15">
        { !!isMobile &&
          <div className="logo">
            <Link to="/">
              <img src={imgLogo} alt="Logo da Lampejo" title="Logo Lampejo"/> 
            </Link>
          </div>
        }

        <div className="rowMd center">
          { !isMobile &&
            <div className="imgStudentMd">
              <img src={imgStudent} alt="Estudante segurando um notebook em um momento de epifania" title="Estudante em Lampejo"/>
            </div>
          }
          <div className="justifyCenterMd">
            <div className="tagline">
              <h1>Estude para o vestibular de graça!<br/><br/> Agora e sempre.</h1>
            </div>

            <div className="buttonContainer">
              <div className={`primaryButton`} onClick={() => { window.location.href = 'https://lampejo.link/register' }}>
                <div className={`buttonInner`}>
                  começar agora!
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <div className={`secondaryButton`} onClick={() => { window.location.href = 'https://lampejo.link/login' }}>
                <div className={`buttonInner`}>
                  já tenho uma conta
                </div>
              </div>
            </div>
          </div>

        </div>

        { !!isMobile &&
          <div className="imgStudent">
            <img src={imgStudent} alt="Estudante segurando um notebook em um momento de epifania" title="Estudante em Lampejo"/>
          </div>
        }
      </div>
      <div className="containerBackground" style={{ backgroundImage: `url(${imgBackground})` }}>
        <div className="rowMd center">
          <Suspense fallback={<div>Carregando...</div>}>
            <CarouselComponent playing={true}/>
          </Suspense>
          <div className="container maxWidthMd350">
            <h3>como funciona?</h3>
            <p>Selecione o tempo e os dias da semana que irá estudar e o cronograma é criado para você.</p>
            <p>Não conseguiu estudar hoje? Ele se reajusta a você automaticamente. Todo dia ficam disponíveis quais tarefas você precisa fazer.</p>
            <div className="buttonContainer">
              <div className={`primaryButton`} onClick={() => { window.location.href = 'https://lampejo.link/register' }}>
                <div className={`buttonInner`}>
                  começar agora!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rowMd center paddingTopMd50">
        <div className="container maxWidthMd450">
          <h2 className="leftTitle">Estamos por<br/> todo o Brasil</h2>
          <p>Faça parte dessa comunidade de esudantes que estão aproveitando a plataforma por todo o país!</p>
          <div className="rowMd rowFlex">
            <div className="column"><p className="numberTitle">14,4k+</p><p>Alunos utilizando a Lampejo!</p></div>
            <div className="column"><p className="numberTitle">260,3k+</p><p>Questões respondidas na plataforma</p></div>
            <div className="column"><p className="numberTitle">122,8k+</p><p>Tarefas concluídas pelos alunos</p></div>
          </div>
        </div>
        { !isMobile && 
          <div className="globeContainer">
            <img src={imgGlobe} alt="globo terrestre, focado no Brasil, com grande parte das capitais em destaque" title="A Lampejo já está sendo utilizada em muitas cidades do Brasil"/>
          </div>
        }
      </div>
      <div className="rowMd center">
        <div className="imgBusPoint maxWidthMd450">
          <img className="imgStudent" src={imgBusPoint} alt="Estudante esperando ônibus, utilizando o aplicativo da Lampejo" title="Lampejo em todos os lugares"/>
        </div>
        <div className="container maxWidthMd350">
          <h2>estamos sempre<br/> com você!</h2>
          <p>Baixe o nosso app para ter a Lampejo sempre disponível</p>
          <div className="maxWidthMd450 alignLeftMd">
            <div className="buttonContainer">
              <div className={`secondaryButton`} onClick={() => { window.location.href = 'https://apps.apple.com/us/app/lampejo-educa%C3%A7%C3%A3o/id1584002205?utm_source=sitelampejoapp&utm_medium=mobilebanner&utm_campaign=mobilebanner' }}>
                <div className={`buttonInner`}>
                  <Suspense fallback={<div>...</div>}>
                    <div className="iconPadding"><IoIosAppstore size={18}/></div>
                  </Suspense>
                  App Store
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <div className={`secondaryButton`} onClick={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.lampejo.lampejoeducacao&hl=pt&utm_source=sitelampejoapp&utm_medium=mobilebanner&utm_campaign=mobilebanner' }}>
                <div className={`buttonInner`}>
                  <Suspense fallback={<div>...</div>}>
                    <div className="iconPadding"><IoMdAppstore size={18}/></div>
                  </Suspense>
                  Google Play
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container paddingTopMd50">
        <h2 className="textCenterMd">Se inscreva agora!<br className="dMdNone"/> É de graça! Agora<br className="dMdNone"/> e sempre</h2>
        <div className="buttonContainer">
          <div className={`primaryButton`} onClick={() => { window.location.href = 'https://lampejo.link/register' }}>
            <div className={`buttonInner`}>
              começar agora!
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h3>quem está falando de nós?</h3>
        <div className="rowMd alignSelfCenterMd" style={{ justifyContent: 'space-evenly', display: 'inline-flexbox' }}>
          { journalPosts.map((post, index) => { return (
            <Suspense key={index} fallback={<div>...</div>}>
              <JournalPostComponent url={post.url} imgSrc={post.imgSrc} imgSkeletonColor={post.imgSkeletonColor}/>
            </Suspense>
          )})}
        </div>
      </div>
      <br/><br/>
      <div className="container">
        <h3>tire suas dúvidas sobre a sua jornada de estudos com nossos artigos</h3>
          <div className="rowMd alignSelfCenterMd">
            { posts.map((post, index) => { return (
              <Suspense key={index} fallback={<div>...</div>}>
                <BlogPostComponent title={post.title} url={post.url} imgSrc={post.imgSrc} imgSkeletonColor={post.imgSkeletonColor}/>
              </Suspense>
            )})}
          </div>
          <div className="buttonContainer">
            <div className={`secondaryButton`}>
              <Link className={`buttonInner`} to="/blog">
                ver todos os artigos
              </Link>
            </div>
          </div>          
      </div>
      <Suspense fallback={<div></div>}>
        <FooterComponent/>
      </Suspense>

      { !!isMobile &&
        <Suspense fallback={<div></div>}>
          <AppDownloadComponent/>
        </Suspense>
      }
    </>
  );
}
