import React, { Suspense, lazy, useState } from 'react';
import { Link } from 'react-router-dom';

import imgLogo from './../images/logo_lampejo.webp';
import imgLogo187 from './../images/logo_lampejo_187.webp';
import Helmet from 'react-helmet';

const FooterComponent = lazy(() => import('./../components/footer'));

export default function NotFound() {
  const [ isMobile, setIsMobile ] = useState(window.screen.width < 700);

  return (
    <>
      <Helmet
        title={`Ooops! - 404 | Lampejo`}
      />
      { !isMobile && 
        <nav className="navHome">
          <div className="maxWidthMd1000">
            <Link to="/">
              <img className="maxWidthMd187" src={imgLogo187} />
            </Link>
          </div>
        </nav>
      }
      <div className="container maxWidthMd1000 topMdMinus15">
        { !!isMobile &&
          <div className="logo">
            <Link to="/">
              <img src={imgLogo} alt="logo Lampejo"/> 
            </Link>
          </div>
        }

        <div className="rowMd center">
          <div className="div404">
            <span>404</span>
          </div>
          
          <div className="justifyCenterMd">
            <div className="tagline tagline404">
              <h1>Oops..<br/><br/> desculpe.. não conseguimos encontrar essa página</h1>
            </div>

            <div className="buttonContainer">
              <div className={`secondaryButton`} onClick={() => { window.location.href = 'https://www.lampejo.app/' }}>
                <div className={`buttonInner`}>
                  voltar para o início
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    
      <Suspense fallback={<div></div>}>
        <FooterComponent/>
      </Suspense>
    </>
  );
}
